import React from "react"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import { kebabCase } from "lodash"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import Content, { HTMLContent } from "../components/Content"
import { Disqus } from "gatsby-plugin-disqus"
import Container from "../components/Container"
import Row from "../components/Row"
import RelatedPosts from "../components/RelatedPosts"
import Layout from "../components/Layout"
import "../components/Styles.css"

export class BlogPostTemplate extends React.Component {
  render() {
    let disqusConfig = {
      url: `${this.props.url + this.props.slug}`,
      identifier: this.props.id,
      title: this.props.title,
    }

    const PostContent = this.props.contentComponent || Content

    return (
      <>
        <SEO title={this.props.title} description={this.props.description} />
        <Layout>
          <section className="section">
            {this.props.helmet}

            <Container>
              <Row>
                <div className="col s12 m8 offset-m2">
                  <span>
                    <Link to="/blog">
                      <span className="blog-post-back-link">Blog</span>
                    </Link>
                    <span> / {this.props.title}</span>
                  </span>
                  <h1 className="blog-post-title">{this.props.title}</h1>
                  <div>
                    <p
                      className="blog-post-date"
                      style={{ display: "inline-block" }}
                    >
                      {this.props.date}&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                      {this.props.time} min read
                    </p>
                  </div>
                  <Link to={`/authors/${kebabCase(this.props.author)}/`}>
                    <h3 className="blog-post-author">{this.props.author}</h3>
                  </Link>
                  {this.props.tags && this.props.tags.length ? (
                    <ul className="taglist">
                      <i class="fas fa-tags"></i>
                      {this.props.tags.map(tag => (
                        <li className="blog-post-tag" key={tag + `tag`}>
                          <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  <PostContent content={this.props.content} />
                  <Link to="/blog">
                    <p className="blog-post-back-link">
                      &#60; Back to the blog
                    </p>
                  </Link>
                  <Disqus config={disqusConfig} />
                </div>
              </Row>
            </Container>
          </section>
          <RelatedPosts tags={this.props.tags} />
        </Layout>
      </>
    )
  }
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

class BlogPost extends React.Component {
  render() {
    const { markdownRemark: post } = this.props.data
    const url = this.props.data.site.siteMetadata.url
    return (
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        author={post.frontmatter.author}
        helmet={
          <Helmet titleTemplate="%s | Beyond the Block, Inc.">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            {/* Font Awesome */}
            <link
              rel="stylesheet"
              href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
              integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
              crossorigin="anonymous"
            />
            {/* Materialize Icons */}
            <link
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
              rel="stylesheet"
            />
            {/* Google Fonts */}
            <link
              href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
              rel="stylesheet"
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        id={post.id}
        slug={post.fields.slug}
        featuredImage={post.frontmatter.featuredimage.childImageSharp.fluid}
        date={post.frontmatter.date}
        time={post.timeToRead}
        url={url}
      />
    )
  }
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        author
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`
