import React, { Component } from "react"
import { kebabCase } from "lodash"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import PreviewCompatibleImage from "./PreviewCompatibleImage"

import Container from "../components/Container"
import Row from "../components/Row"

class RelatedPosts extends Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const tags = this.props.tags
    const relatedPosts = []
    const relatedPostIDs = []
    let relatedPostsDeduped = []

    if (!!tags) {
      // Find all posts with same tag(s)
      for (var i = 0; i < tags.length; i++) {
        for (var j = 0; j < posts.length; j++) {
          for (var k = 0; k < posts[j].node.frontmatter.tags.length; k++) {
            if (tags[i] === posts[j].node.frontmatter.tags[k]) {
              relatedPosts.push(posts[j])
              relatedPostIDs.push(posts[j].node.id)
            }
          }
        }
      }

      // Remove dupes caused by multiple matching tags
      function removeDuplicates(array) {
        return array.filter((a, b) => array.indexOf(a) === b)
      }

      if (relatedPosts.length > 0) {
        for (var l = 0; l < relatedPostIDs.length; l++) {
          if (removeDuplicates(relatedPostIDs)[l] === relatedPosts[l].node.id) {
            relatedPostsDeduped.push(relatedPosts[l])
          }
        }

        // Remove first item, which always shows the current post as the first related
        relatedPostsDeduped.shift()
      }

      // If no related tags, fill with most recent blogs
      if (relatedPostsDeduped.length === 0) {
        relatedPostsDeduped = posts

        // If less than 4 posts total, use all available posts, return out of logic
        if (relatedPostsDeduped.length < 4) {
        } else {
          // Get the most recent 4 posts
          for (var m = relatedPostsDeduped.length; m > 4; m--) {
            relatedPostsDeduped.pop()
          }

          // If less than 4 related, fill the rest with most recent blogs
          if (relatedPostsDeduped.length < 4) {
            for (var n = relatedPostsDeduped.length; n < 4; n++) {
              relatedPostsDeduped.push(posts[n])
            }
          }
        }
      }
    }

    return (
      <>
        <Helmet>
          {/* Font Awesome */}
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
            integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
            crossorigin="anonymous"
          />
          {/* Materialize Icons */}
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          {/* Google Fonts */}
          <link
            href="https://fonts.googleapis.com/css?family=Oswald&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <br />
        <br />
        <Container>
          <Row>
            <div className="col s12">
              <h2 className="blog-related-header">
                You might also be interested in...
              </h2>
            </div>
          </Row>
          <Row>
            {relatedPostsDeduped &&
              relatedPostsDeduped.map(({ node: post }) => (
                <div className="col s12 m3" key={post.id}>
                  <article className="card related-post-card">
                    {post.frontmatter.featuredimage ? (
                      <div className="card-image">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="card-content">
                      <p>
                        <Link
                          className="landing-page-header blog-title"
                          to={post.fields.slug}
                        >
                          {post.frontmatter.title}
                        </Link>
                        <br />
                        <div>
                          <span
                            className="blog-post-date"
                            style={{ display: "inline-block" }}
                          >
                            {post.frontmatter.date}
                            &nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                            {post.timeToRead} min read
                          </span>
                        </div>
                      </p>
                      <Link
                        className="blog-post-date"
                        to={`/authors/${kebabCase(post.frontmatter.author)}`}
                      >
                        {post.frontmatter.author}
                      </Link>
                      <p className="blog-description-related">
                        {post.frontmatter.description.substring(0, 110)}...
                      </p>
                    </div>
                    <Link
                      className="button blog-roll-link"
                      to={post.fields.slug}
                    >
                      Keep Reading &#62;
                    </Link>
                  </article>
                </div>
              ))}
          </Row>
        </Container>
      </>
    )
  }
}

RelatedPosts.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default tags => (
  <StaticQuery
    query={graphql`
      query RelatedPostsQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              timeToRead
              frontmatter {
                author
                description
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                tags
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 450, maxHeight: 300, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <RelatedPosts data={data} count={count} tags={tags} />
    )}
  />
)
